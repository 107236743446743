body.login-page {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

body.login-page #root{
  width: 100%;
}

body.login-page img{
  width: 100%;
  max-width: 420px;
  margin: 30px auto;
}

body.login-page form {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

body.login-page input{
  margin: 15px auto;
}

body.login-page button{
  width: 100%;
}
