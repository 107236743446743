.container-fluid{
  padding-left: 0;
}
.sidebar a{
  color: #666;
}
.sidebar a:hover{
  color: #165382;
}
.sidebar{
  border-right: #eee 2px solid;
  top: 4rem;
  z-index: 1000;
  height: 100%;
}
.sidebar .active{
  background-color: #eee;
}
.sidebar .active a{
  color: #165382;
}
main{
  padding-top: 30px;
}
.tmp-alert{
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}
.table-first-col-thin tr th:nth-child(1),
.table-first-col-thin tr td:nth-child(1){
  width: 10px;
  padding-right: 0;
}

.orders-list tr td:nth-child(1){
  width: 4px;
  padding: 0;
  margin: 0;
}

.orders-list .collapsed{
  width: 2%;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.orders-list a.btn-warning svg{
  color: #fff;
}

.orders-list tbody>tr:hover{
  background-color: #f6f6f6;
}

.orders-list .check{
  color: lightgrey;
}
.orders-list .check:hover{
  color: #007bff;
}
.orders-list .route,
.report-receivable .route{
  font-size: 0.9em
}
.orders-list .driver,
.report-receivable .driver{
  white-space: nowrap;
}
.orders-list .id,
.report-receivable .id{
  font-size: 0.8em;
  width: 1em;
  padding: 2px;
  vertical-align: middle;
}
.order-details th {
  vertical-align: middle;
  font-size: 0.9em;
}

.order-details .container{
  margin-top: 10px;
}

.loading-spinner {
  width: 20%;
  position: absolute;
  right: 1em;
}
.data-loader{
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.data-loader>div{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 3em;
  color: #eee;
}

.mileage-table td{
  white-space: nowrap;
}
.items-list tr td:last-child{
  text-align: right;
}
.maintenance .card{
  width: 32%;
  margin: 0.5em 0.3em;
}
/*
.maintenance .type-tractor,
.maintenance .type-truck{
  background: url('/img/truck-icon1.png') no-repeat top right;
  background-size: 20%;
}
.maintenance .type-trailer{
  background: url('/img/trailer-icon.png') no-repeat 98% 5px;
  background-size: 13%;
}
.maintenance .type-car{
  background: url('/img/car-icon.png') no-repeat 98% 20px;
  background-size: 15%;
}
*/
.maintenance .card-title strong{
  font-weight: bold;
  font-size: 1.3em;
  letter-spacing: 0.05em;
  border: #eee 2px solid;
  border-radius: 5px;
  padding: 5px 20px;
}

.maintenance .card-title span{
  display: block;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 2em;
  height: 2em;
}

.maintenance .card table tr td:last-child{
  text-align: right;
}

.maintenance .card .badge{
  position: absolute;
  top: 0;
  right: 0;
  color: #eee;
  font-size: 3em;
}

.maintenance .header{
  height: 2.5em;
  padding: 0 10px;
}

.maintenance .header h3,
.maintenance .header .search-bar{
  display: inline-block;
  width: 50%;
}

.maintenance .header .search-bar{
  text-align: right;
}

.maintenance .header .input-group{
  width: 16em;
  display: inline-flex;
}

.maintenance .cards,
.docs{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.page{
  margin: 0 10px;
}

.page>div{
  width: 200px;
  height: 250px;
  position: relative;
}
.page>div>div{
  border: 2px #ccc solid;
  border-radius: 3px;
  height: 248px;
  padding: 5px;
}
.page>div>div strong{
  font-size: 1.5em;
  font-weight: bold;
}
.page>div .corner{
  background-color:#fff;
  width: 30px;
  height: 30px;
  border-left: 2px #ccc solid;
  border-bottom: 2px #ccc solid;
  position: absolute;
  top: 0;
  right: 0;
}
.page>div .corner svg{
  stroke:rgb(204,204,204);
  stroke-width:2;
}
.page .buttons{
  text-align: center;
  margin-top: 20px;
}
.page .tax-registred{
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8em;
  margin-top: 10px;
  background-color: #efefef;
}
.PDFViewer{
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
.PDFViewer iframe{
  border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
.close-pdf{
  position: absolute;
  right: 3%;
  top: 0;
}
.postservice {
  margin-top: 10pt;
}
.error-page{
  margin: 0 3em;
}
.error-details{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1em;
  background-color: #fefefe;
}
label span{
  font-size: 0.7em;
  font-style: italic;
}
.no-wrap{
  white-space: nowrap;
}
.exp-period{
  width: 5.1em;
  margin: 0 0.3em;
}
.exp-period>div{
  border: none;
}
.exp-period input,
.exp-period span{
  border-bottom: 1px dashed black;
}
.exp-period-calendar{
  font-size: 0.5em;
}
.dashboard-trips thead th{
  text-align: center;
}
.dashboard-trips tbody th{
  font-size: 0.9em;
  vertical-align: middle;
}
.dashboard-trips .badge{
  width: 100%;
  font-size: 0.9em;
  padding: 0.7em;
  font-weight: 100;
  border-radius: 10px;
}
.dashboard-trips .trip-end{
  border-radius: 0 10px 10px 0;
}
.dashboard-trips .trip-start{
  border-radius: 10px 0 0 10px;
}
.report-drivers-calendar th,
.report-drivers-calendar td{
  border-color: #ccc !important;
}
.report-drivers-calendar td{
  text-align: center;
  position: relative;
  padding: 1.5em 0;
  width: 14%;
}
.report-drivers-calendar .full{
  width: 100%;
  border-radius: 0;
  float: left;
}
.report-drivers-calendar .end{
  border-radius: 0 4px 4px 0 !important;
  width: 45%;
  float: left;
}
.report-drivers-calendar .start{
  border-radius: 4px 0 0 4px !important;
  width: 45%;
  float: right;
}
.report-drivers-calendar .start-end{
  width: 90%
}
.report-drivers-calendar .date{
  position: absolute;
  top: 0.1em;
  left: 50%;
  transform: translateX(-50%);
}
.order-view .header{
  background-color: lightgrey;
  font-size: 1.2em;
  text-align: center;
}
.report-receivable{
  position: relative;
  padding-top: 2em;
}
.report-receivable .total{
  position: absolute;
  top: 0;
}
.report-receivable td.name{
  padding-left: 3em;
}
.report-receivable th.head{
  background-color: lightgrey;
  padding-left: 1em;
}
.report-drivers-duty thead td{
  font-size: 0.7em;
  width: 2.5em;
  text-align: center;
  vertical-align: middle;
}
.report-drivers-duty tbody a{
  font-size: 0.9em;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.report-drivers-duty{
  overflow: hidden;
}
.report-drivers-duty td,
.report-drivers-duty th {
  position: relative;
}
.report-drivers-duty td:hover::after,
.report-drivers-duty th:hover::after {
  content: "";
  position: absolute;
  background-color: rgba(0,0,0,0.075);
  left: 0;
  top: -5000px;
  height: 10000px;
  width: 100%;
  z-index: -1;
}
.report-drivers-duty td.holiday,
.report-drivers-duty th.holiday{
  background-color: rgba(255,0,0,0.075);
}
.tooltip-inner ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
